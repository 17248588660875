import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const Instructions = () => {
  return (
    <div id="instructions">
      <Navbar absolute />

      <div className="container d-flex align-items-center flex-column justify-content-center vh-100">
        <h1 className="text-center text-uppercase">
          How to complete the{" "}
          <span className="red-highlight">UTGO&nbsp;experience</span>
        </h1>

        <div id="instructions-container" className="mt-4">
          <div className="instruction-step">
            <div className="red-circle">1</div>
            COMPLETE THE&nbsp;
            <Link className="link" to="/form">
              FORM
            </Link>
          </div>
          <div className="instruction-step">
            <div className="red-circle">2</div>
            Answer the four Questions
          </div>
          <div className="instruction-step">
            <div className="red-circle">3</div>
            Scan the QR code at the Kiosk
          </div>
          <div className="instruction-step">
            <div className="red-circle">4</div>
            Check your email
          </div>
          <div className="instruction-step">
            <div className="red-circle">5</div>
            Go in store
          </div>
        </div>
        <Link
          to="/form"
          className="mt-2 btn text-center red-highlight button border-0 p-1 px-4 fs-3"
        >
          START
        </Link>
      </div>

      <Footer />
    </div>
  );
};

export default Instructions;
