import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/main.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Idle from "./pages/Idle";
import Reward from "./pages/Reward";
import UserForm from "./pages/UserForm";
import Quiz from "./pages/Quiz";
import Result from "./pages/Result";
import Instructions from "./pages/Instructions";
import Admin from "./pages/Admin";

export const IS_DEV = false;
export const API_URL = IS_DEV ? "https://utgo.au/api" : `/api`;

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Default Page */}
        <Route path="*" element={<Instructions />} />

        {/* Admin Page */}
        <Route path="/operator_screen" element={<Admin />} />

        {/* Monitor Page */}
        <Route path="/kiosk_screen" element={<Idle />} />

        {/* Mobile Pages */}
        <Route path="/form" element={<UserForm />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/result" element={<Result />} />
      </Routes>
    </BrowserRouter>
  );
}
