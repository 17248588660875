import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { Form, Button, Alert } from "react-bootstrap";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../App";

const UserForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [marketingChecked, setMarketingChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};

    if (!name.trim()) {
      newErrors.name = "Name is required";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email address";
    }

    // const phoneRegex = /^\d{10}$/;
    // if (!phone || !phoneRegex.test(phone)) {
    //   newErrors.phone = "Please enter a valid 10-digit phone number";
    // }

    if (!termsChecked) {
      newErrors.terms = "You must accept the Terms & Conditions";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length === 0) {
      const userData = new FormData();
      userData.append("name", name);
      userData.append("email", email);
      userData.append("terms", termsChecked ? 1 : 0);
      userData.append("marketing", marketingChecked ? 1 : 0);

      postForm(userData);
    } else {
      setErrors(formErrors);
    }
  };

  const postForm = async (formData) => {
    try {
      const response = await fetch(`${API_URL}/start`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      console.log(data);

      navigate("/quiz", { state: { questions: data.questions } });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div id="user-form">
      <Navbar />
      <div className="tall-margin d-flex align-items-center justify-content-center">
        <img src="/assets/logo.gif" className="w-50" alt="Logo" />
      </div>
      <Form className="p-3" onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="name">
          <Form.Control
            type="text"
            placeholder="NAME"
            value={name}
            onChange={(e) => setName(e.target.value)}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="email">
          <Form.Control
            type="email"
            placeholder="EMAIL"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3 d-flex" controlId="terms">
          <Form.Check
            type="checkbox"
            className="me-2"
            checked={termsChecked}
            onChange={(e) => setTermsChecked(e.target.checked)}
            isInvalid={!!errors.terms}
          />
          <Form.Label className="m-0">
            I have read and agree to the{" "}
            <a
              target="_blank"
              href="https://faq-au.uniqlo.com/pkb_Home?id=kA3Ie00000000oz&l=en_US&c=category_uq%3AUQAU_C7_1&fs=Search&pn=1"
              className="link-red"
            >
              Terms & Conditions
            </a>{" "}
            and{" "}
            <a
              target="_blank"
              href="https://faq-au.uniqlo.com/pkb_Home?id=kA37F0000000csl&q=privacy&l=en_US&fs=Search&pn=1"
              className="link-red"
            >
              UNIQLO Privacy Policy
            </a>
          </Form.Label>
        </Form.Group>
        {errors.terms && (
          <Alert variant="danger" className="mb-3 p-2">
            {errors.terms}
          </Alert>
        )}

        <Form.Group className="mb-3 d-flex" controlId="marketing">
          <Form.Check
            type="checkbox"
            className="me-2"
            checked={marketingChecked}
            onChange={(e) => setMarketingChecked(e.target.checked)}
          />
          <Form.Label className="m-0">
            I would like to receive promotional and other marketing emails from
            UNIQLO
          </Form.Label>
        </Form.Group>

        <div className="text-center">
          <Button
            type="submit"
            className="btn btn-primary bg-red border-0 px-5 py-2"
          >
            START QUIZ
          </Button>
        </div>
      </Form>

      <Footer />
    </div>
  );
};

export default UserForm;
